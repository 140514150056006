import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import { Dialog, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import AppDataProvider from "../AppDataProvider";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import MainAppToolbar from "../MainAppToolbar/MainAppToolbar";
import Login from "../../Accounts/Login";
import AppLayoutSmallScreen from "./AppLayoutSmallScreen";
import AppLayoutLargeScreen from "./AppLayoutLargeScreen";
import MunicipalityFilterDataProvider from "../MainFilters/MunicipalityFilter/MunicipalityFilterDataProvider";
import { useGlobalContext } from "../../../context";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import useParamsFromUrl from "../hooks/useParamsFromUrl";
import { AD_TYPE_OPTIONS } from "../../../utils/adOptions";

// // Requests permissions to show notifications.
// async function requestNotificationsPermissions() {
//   console.log("Requesting notifications permission...");
//   const permission = await Notification.requestPermission();

//   if (permission === "granted") {
//     console.log("Notification permission granted.");
//     // Notification permission granted.
//     // await saveMessagingDeviceToken();
//   } else {
//     console.log("Unable to get permission to notify.");
//   }
// }

function App() {
  const { isSmallOrMobile } = useCustomMediaQuery();
  const { state, routerState, dispatchRouter } = useGlobalContext();
  const params = useParamsFromUrl();

  const appRef = useRef(null);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "mainApp" },
    });
    console.log("routerState", routerState);
  }, []);

  return (
    <AppDataProvider>
      <Helmet>
        <title>
          {`${
            AD_TYPE_OPTIONS.find(
              (option) => option.value === (params?.ad_type || "flat_sale")
            ).label
          } - Paieškos rezultatai (${state.resultCount})`}
        </title>
      </Helmet>
      <MunicipalityFilterDataProvider>
        <Dialog
          open={openLoginDialog}
          onClose={() => setOpenLoginDialog(false)}
          maxWidth="xs"
          fullWidth
          fullScreen
        >
          <DialogContent>
            <Login open={openLoginDialog} setOpen={setOpenLoginDialog} />
          </DialogContent>
        </Dialog>
        <MainAppToolbar />
        <Box
          component="div"
          className="App"
          ref={appRef}
          sx={{
            border: "none",
            pt: 13,
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isSmallOrMobile && <AppLayoutSmallScreen />}
          {!isSmallOrMobile && <AppLayoutLargeScreen />}
        </Box>
      </MunicipalityFilterDataProvider>
    </AppDataProvider>
  );
}

export default App;
