import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DropZoneComponent from "../../../AdForm/Step2Photos/DropZoneComponent";
import DragDropPhotoList from "../../../AdForm/Step2Photos/DragDropPhotoList";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import useBackHandler from "../../../../hooks/useBackHandler";
import { useHistory } from "react-router-dom";

function EditPhotosDialog({ open, setOpen, ad }) {
  const [disableDrag, setDisableDrag] = useState(false);
  const { isMobile } = useCustomMediaQuery();
  const history = useHistory();
  useBackHandler(handleClose);

  function handleMouseDown(e) {
    e.stopPropagation(); // to prevent sliding in the background
    setDisableDrag(false);
  }

  function handleClose(event, reason) {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  }

  console.log("editPhotosDialog");

  return (
    <>
      <Box sx={{ border: "none" }} onMouseDown={handleMouseDown}>
        <Dialog
          fullScreen={isMobile}
          open={open}
          onClose={() => {
            history.goBack();
            handleClose();
          }}
          maxWidth="lg"
        >
          <DialogTitle sx={{ minWidth: "500px" }}>
            Tvarkykite nuotraukas
          </DialogTitle>
          <DialogContent sx={{ border: "none" }}>
            <DropZoneComponent ad={ad} />

            {ad.properties.photos.length > 1 && (
              <Grid
                container
                xs={12}
                sx={{ display: "flex", alignItems: "end", mt: 1 }}
              >
                <Typography
                  variant="body2"
                  sx={{ border: "none", mt: 2, ml: 1, mb: -2 }}
                >
                  Galite keisti nuotraukų eiliškumą vilkdami jas{" "}
                  {!isMobile && "pele"}
                </Typography>
              </Grid>
            )}
            <DragDropPhotoList
              ad={ad}
              disableDrag={disableDrag}
              setDisableDrag={setDisableDrag}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                history.goBack();
                handleClose();
              }}
              autoFocus
              variant="contained"
            >
              Uždaryti
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default EditPhotosDialog;
