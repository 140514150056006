import React, { useState } from "react";
import { Card, CardMedia, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-image-crop/dist/ReactCrop.css";
import { AiOutlineRotateRight } from "react-icons/ai";
import { Box } from "@mui/system";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import { DEFAULT_ASPECT_RATIO as ASPECT } from "../../../utils/defaults";
import PhotoEditor from "./PhotoEditor";
import { useGlobalContext } from "../../../context";
import { useEffect } from "react";
// import PhotoRotator from "./PhotoRotator";
import {
  deletePhoto,
  rotatePhoto,
} from "../../Dashboard/fetchOrSendDashboardData";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { getFullImgUrl } from "../../../utils/getFullImgUrl";

function PhotoCard({ photo, index, disableDrag, setDisableDrag, isDraft }) {
  const { dispatch, dispatchDashboard, dispatchAdForm } = useGlobalContext();
  const [imgSrc, setImgSrc] = useState(photo?.photo_crop);
  const [editPhoto, setEditPhoto] = useState(false);
  const [isUpdatingPhoto, setIsUpdatingPhoto] = useState(false);
  // const [rotatePhoto, setRotatePhoto] = useState(false);
  const [isImageReady, setIsImageReady] = useState(true);
  // const [isRotatingPhoto, setIsRotatingPhoto] = useState(false);
  const { isMobile } = useCustomMediaQuery();
  let dispatchX = !!isDraft ? dispatchAdForm : dispatchDashboard;

  let filename = "photo";
  if (!!photo?.photo) {
    const fullFilename = photo?.photo?.substring(
      photo?.photo?.lastIndexOf("/") + 1
    );
    filename = fullFilename.substring(0, fullFilename.lastIndexOf("_full"));
  }

  // switch between dragging and editing modes
  useEffect(() => {
    if (disableDrag === false) {
      setEditPhoto(false);
    }
  }, [disableDrag]);

  useEffect(() => {
    if (!editPhoto) setDisableDrag(false);
  }, [editPhoto]);

  // set image source when it is fully loaded
  useEffect(() => {
    if (
      // rotatePhoto ||
      isUpdatingPhoto
    ) {
      setIsImageReady(false);
      const img = new Image();
      img.src = getFullImgUrl(photo.photo_crop);
      img.onload = () => {
        setImgSrc(img.src);
        // setRotatePhoto(false);
        setIsImageReady(true);
      };
    }
  }, [photo?.photo_crop]);

  function handleCardClick(e) {
    if (editPhoto) e.stopPropagation();
  }

  function handleDelete() {
    let photoId = photo.id;
    deletePhoto(photoId, dispatchX, dispatch);
  }

  function handleEditPhoto() {
    setEditPhoto(true);
    setDisableDrag(true);
  }

  function handleRotate() {
    rotatePhoto(photo.id, dispatchX);
    // setRotatePhoto(true);
  }

  console.log("photo from PhotoCard", photo);
  // console.log("rotatePhoto", rotatePhoto);
  // console.log("rotatePhoto", rotatePhoto, "photo_crop", photo?.photo_crop);
  console.log("imgSrc", !!imgSrc);
  console.log("isImageReady", isImageReady);
  console.log("index from photoCard", index);
  console.log("photo crop url", photo);

  return (
    <Card
      sx={{ width: isMobile ? "100%" : "300px", border: "solid 1px lightgrey" }}
      onMouseDown={handleCardClick}
      elevation={0}
      // sx={{ borderColor: "primary.main" }}
    >
      {!editPhoto && (
        <>
          <Grid
            container
            sx={{
              border: "none",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Typography variant="body2" sx={{ ml: 1 }}>
              {index === 0 ? "Pagrindinė nuotr." : `${index + 1} nuotr.`}
            </Typography>
            <Box sx={{ border: "none" }}>
              <IconButton size="small" onClick={handleEditPhoto}>
                <PhotoSizeSelectLargeIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={handleRotate}
                // disabled={rotatePhoto}
              >
                <AiOutlineRotateRight fontSize="19px" />
              </IconButton>
              <IconButton size="small" onClick={handleDelete}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
          <CardMedia
            onClick={handleEditPhoto}
            image={
              // rotatePhoto ||
              isUpdatingPhoto || !isImageReady
                ? imgSrc
                : getFullImgUrl(photo.photo_crop)
            }
            title={getFullImgUrl(photo.photo_crop)}
            sx={{ width: "100%", aspectRatio: String(ASPECT) }}
          />

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ pl: 1, my: 1, border: "none" }}
          >
            <Box sx={{ maxWidth: "200px" }}>
              <Typography
                variant="caption"
                color="textSecondary"
                component="p"
                noWrap
              >
                {filename}
              </Typography>
            </Box>
          </Grid>
        </>
      )}

      {/* {rotatePhoto && (
        <>
          <PhotoRotator
            photo={photo}
            setRotatePhoto={setRotatePhoto}
            setIsUpdatingPhoto={setIsUpdatingPhoto}
            setImgSrc={setImgSrc}
            isDraft={isDraft}
          />
        </>
      )} */}

      {editPhoto && (
        <Box>
          <PhotoEditor
            photo={photo}
            setEditPhoto={setEditPhoto}
            setIsUpdatingPhoto={setIsUpdatingPhoto}
            setImgSrc={setImgSrc}
            isDraft={isDraft}
          />
        </Box>
      )}
    </Card>
  );
}

export default PhotoCard;
