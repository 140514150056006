import React, { useState, useRef, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NavLink, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Badge, CssBaseline, Grid, Stack, Tooltip } from "@mui/material";
import { useGlobalContext } from "../../../context";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/system";
// import HomeWorkIcon from "@mui/icons-material/HomeWork";
// import HouseIcon from "@mui/icons-material/House";
// import RoofingIcon from "@mui/icons-material/Roofing";
// import HomeIcon from "@mui/icons-material/Home";
// import CottageIcon from "@mui/icons-material/Cottage";

import PostAddIcon from "@mui/icons-material/PostAdd";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CustomAvatar from "../CustomAvatar";
import Notification from "./Notification/Notification";
import HeaderDataProvider from "./HeaderDataProvider";
import UserMenu from "./UserMenu/UserMenu";
import LocationSearch from "./LocationSearch/LocationSearch";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { grey } from "@mui/material/colors";
import LoginDialog from "../../Accounts/LoginDialog";
import CookieConsentDrawer from "./CookieConsentDrawer";
import useCurrentWidth from "../../../hooks/useCurrentWidth";
import DashboardToolBar from "../../Dashboard/DashboardTabs/DashboardToolBar";
import reloadAds from "../../../utils/getUrlFromParams";
import SnackbarComponent from "../../SharedComponents/SnackbarComponent";

function Header() {
  const {
    dispatch,
    userState,
    dispatchUser,
    notificationState,
    routerState,
    dispatchRouter,
    dashboardState,
  } = useGlobalContext();
  const { isMobile, isSmallOrMobile } = useCustomMediaQuery();
  const history = useHistory();
  const appBarRef = useRef(null);

  const { width } = useCurrentWidth();

  const [openSearch, setOpenSearch] = useState(false);
  let location = useLocation();
  const unseenResults = dashboardState?.mySearches
    .map((option) => option.unseen_results_count)
    .some((result) => result > 0);

  useEffect(() => {
    if (appBarRef.current) {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { appBarHeight: appBarRef.current.offsetHeight },
      });
    }
  }, []);

  function handleMainLogoClick() {
    if (routerState.from === "mainApp") {
      // reload ads when main logo is clicked from main app view
      reloadAds(routerState.appParams, dispatch);
    }

    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { mainLogoClicked: true, from: "mainApp" },
    });
    history.push(routerState.mainAppUrlString);
  }

  console.log("notificationState from Header", notificationState);
  console.log("routerState from Header", routerState);
  console.log("isauthenticated--->", userState.isAuthenticated);
  console.log("userState.returning--->", userState.returning);

  return (
    <HeaderDataProvider>
      <CssBaseline />
      {userState.openLoginDialog && <LoginDialog />}
      <AppBar
        ref={appBarRef}
        position="fixed"
        // color="default"
        // position="sticky"
        elevation={0}
        sx={{
          // pt: 0.5,
          // pb: 1,
          // backgroundColor: "rgba(245,245,245, 0.7)",
          // backgroundColor: "rgba(255, 255, 255, 0.7)",
          // backgroundColor:
          //   routerState.from === "mainApp"
          //     ? "white"
          //     : "rgba(255, 255, 255, 0.7)",
          backgroundColor: "white",
          backdropFilter: "blur(10px)", // blur the content behind the AppBar
          borderBottom: "1px solid lightgrey",
          py: 0.5,
        }}
      >
        <Toolbar variant="dense" disableGutters>
          <Grid
            container
            item
            xs={12}
            sx={{
              display: "flex",
              // width: 1,
              justifyContent: "space-between",
              alignItems: "center",
              border: "none",
              mx: 0,
            }}
          >
            {!openSearch && (
              <Box
                sx={{
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleMainLogoClick}
              >
                <Box
                  component="img"
                  src={width > 350 ? "/logo.png" : "/logo_small.png"}
                  sx={{ ml: 1, width: "auto", height: isMobile ? 35 : 40 }}
                />
                {/* <Box
                  sx={{
                    border: "none",
                    ml: 1,
                    py: 0.5,
                    px: 1,
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: green[100],
                  }}
                >
                  <HomeIcon
                    color="white"
                    sx={{
                      fontSize: "28px",
                      border: "solid white 2px",
                      borderRadius: "50%",
                      backgroundColor: "red",
                    }}
                  />
                </Box> */}
                {/* {width >= 350 && (
                  <Stack sx={{ mt: 1, py: 0.5, px: 1 }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        variant="body2"
                        color={blue[900]}
                        fontWeight="bold"
                        noWrap
                      >
                        kur
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      color={blue[900]}
                      fontWeight="bold"
                      noWrap
                      sx={{ mt: -1.5, border: "none" }}
                    >
                      namai
                    </Typography>
                  </Stack>
                )} */}
              </Box>
            )}

            {(!isMobile || openSearch) && (
              <Box
                sx={{
                  // display: { xs: openSearch ? "flex" : "None", sm: "flex" },
                  flexGrow: isMobile ? 1 : 0.7,
                  alignItems: "center",
                  border: "none",
                  borderRadius: isMobile ? "3px" : "25px",
                  backgroundColor: grey[100],
                  pl: 2,
                  pr: 1,
                  maxWidth: 500,
                  mx: 0,
                  position: "relative",
                  height: isMobile ? 57 : "auto",
                }}
              >
                <LocationSearch
                  openSearch={openSearch}
                  setOpenSearch={setOpenSearch}
                />
                {unseenResults && !isMobile && (
                  <Badge
                    color="secondary"
                    variant="dot"
                    sx={{ position: "absolute", top: 4, right: 2 }}
                  />
                )}
              </Box>
            )}

            {!openSearch && (
              <Box
                sx={{
                  // display: { xs: openSearch ? "None" : "flex", sm: "flex" },
                  alignItems: "center",
                  border: "none",
                  mx: 0.5,
                  display: "flex",
                }}
              >
                {isMobile && (
                  <Box sx={{ position: "relative" }}>
                    <IconButton
                      size="small"
                      onClick={() => setOpenSearch(true)}
                      sx={{ mx: 0.5 }}
                    >
                      <SearchIcon color="default" fontSize="medium" />
                    </IconButton>
                    {unseenResults && (
                      <Badge
                        color="secondary"
                        variant="dot"
                        sx={{ position: "absolute", top: 5, right: 12 }}
                      />
                    )}
                  </Box>
                )}

                {isSmallOrMobile && (
                  <Tooltip title="Įkelti skelbimą">
                    <IconButton
                      size="small"
                      component={NavLink}
                      to="/create-ad"
                      color={
                        routerState.from === "createAd" ? "primary" : "success"
                      }
                      sx={{ mx: 0.5 }}
                      onClick={() => {
                        dispatchRouter({
                          type: "CHANGE_ROUTER_STATE",
                          payload: { from: "createAd" },
                        });
                      }}
                    >
                      <PostAddIcon
                        // color={
                        //   location.pathname.includes("create-ad")
                        //     ? "primary"
                        //     : "text.secondary"
                        // }
                        fontSize="medium"
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {!isSmallOrMobile && (
                  <Button
                    variant={
                      routerState.from === "createAd" ? "outlined" : "contained"
                    }
                    color={
                      routerState.from === "createAd" ? "primary" : "success"
                    }
                    onClick={() => {
                      dispatchRouter({
                        type: "CHANGE_ROUTER_STATE",
                        payload: { from: "createAd" },
                      });
                      history.push("/create-ad");
                    }}
                    sx={{ mr: 1, px: 1 }}
                    startIcon={<PostAddIcon />}
                  >
                    <Typography variant="body2">Įkelti skelbimą</Typography>
                  </Button>
                )}

                {!userState?.isAuthenticated ? (
                  <>
                    <Tooltip title="Prisijungti">
                      <IconButton
                        size="small"
                        onClick={() => {
                          if (
                            ![
                              "login",
                              "register",
                              "passwordReset",
                              "passwordResetConfirm",
                              "activate",
                            ].includes(routerState.from)
                          ) {
                            dispatchUser({
                              type: "CHANGE_USER_STATE",
                              payload: { openLoginDialog: true },
                            });
                          }
                        }}
                      >
                        <CustomAvatar />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    {isSmallOrMobile && (
                      <Tooltip title="Vartotojo sritis">
                        <IconButton
                          size="small"
                          sx={{ mx: 0.5 }}
                          onClick={() => {
                            dispatchRouter({
                              type: "CHANGE_ROUTER_STATE",
                              payload: { from: "dashboard" },
                            });
                            history.push(
                              !!routerState.dashboardUrlString
                                ? routerState.dashboardUrlString
                                : "/dashboard/my-favorites"
                            );
                          }}
                        >
                          <AdminPanelSettingsIcon
                            color={
                              location.pathname.includes("dashboard")
                                ? "primary"
                                : "text.secondary"
                            }
                            fontSize="medium"
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!isSmallOrMobile && (
                      <Button
                        variant="outlined"
                        sx={{
                          mr: 0.5,
                          color: location.pathname.includes("dashboard")
                            ? "primary"
                            : "text.secondary",
                          borderColor: location.pathname.includes("dashboard")
                            ? "primary"
                            : "lightgrey",
                        }}
                        startIcon={<AdminPanelSettingsIcon />}
                        onClick={() => {
                          dispatchRouter({
                            type: "CHANGE_ROUTER_STATE",
                            payload: { from: "dashboard" },
                          });
                          history.push(
                            !!routerState.dashboardUrlString
                              ? routerState.dashboardUrlString
                              : "/dashboard/my-favorites"
                          );
                        }}
                      >
                        <Typography variant="body2">
                          Vartotojo sritis
                        </Typography>
                      </Button>
                    )}
                    <Notification />
                    <UserMenu />
                  </>
                )}
              </Box>
            )}
          </Grid>
        </Toolbar>
        {routerState.from === "dashboard" && <DashboardToolBar />}
        {/* {routerState.from === "individualAd" && <AdToolbar />} */}
      </AppBar>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: grey[300],
          zIndex: -1, // Ensure it stays in the background
        }}
      />
      {userState.cookieConsentGiven === false && <CookieConsentDrawer />}
      {userState?.snackbar?.open && <SnackbarComponent />}
    </HeaderDataProvider>
  );
}

export default Header;
