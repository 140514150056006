import { useEffect } from "react";
import { useGlobalContext } from "../../context";
import {
  fetchAdCards,
  fetchAds,
  fetchLikedAdIds,
} from "./fetchOrSendMainAppData";
import useParamsFromUrl from "./hooks/useParamsFromUrl";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

function AppDataProvider({ children }) {
  const { state, dispatch, dispatchRouter, userState } = useGlobalContext();
  const params = useParamsFromUrl();
  const location = useLocation();
  const { adTypeLabel } = useParams();

  // useEffect(() => {
  //   dispatch({
  //     type: "CHANGE_APP_STATE",
  //     payload: { adsLoaded: false, adCardsLoaded: false },
  //   });
  // }, [dispatch, url]);

  // to make sure that ads are loaded when user clicks back or forward button
  useEffect(() => {
    const handlePopState = (event) => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { adsLoaded: false, adCardsLoaded: false },
      });
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (!state.adCardsLoaded && !state.adCardsLoading) {
      fetchAdCards(params, dispatch);
    }
    if (!state.adsLoaded && !state.adsLoading) {
      fetchAds(params, dispatch);
    }
    if (
      userState.isAuthenticated &&
      !state.likedAdIdsLoaded &&
      !state.likedAdIdsLoading
    ) {
      fetchLikedAdIds({}, dispatch);
    }
  }, [
    dispatch,
    params,
    state.adCardsLoaded,
    state.adCardsLoading,
    state.adsLoaded,
    state.adsLoading,
    state.likedAdIdsLoaded,
    state.likedAdIdsLoading,
    userState.isAuthenticated,
  ]);

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { appParams: params },
    });
  }, [JSON.stringify(params)]);

  console.log("params from AppDataProvider", params);
  // console.log("routerState", routerState);

  return children;
}

export default AppDataProvider;
