import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import FavoriteIcon from "@mui/icons-material/Favorite";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import useHandleLikeClick from "../../../hooks/useHandleLikeClick";
import ShareAd from "./ShareAd";
import NavigatePreviousNext from "./NavigatePreviousNext";
import { useGlobalContext } from "../../../context";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import ReportAdDialog from "./ReportAdDialog";

export default function AdToolbar({ ad, setActiveSlide }) {
  const { userLikes, handleLikeClick } = useHandleLikeClick(ad?.id);
  const { routerState, dispatchRouter, userState } = useGlobalContext();
  const { isSmallOrMobile } = useCustomMediaQuery();
  const history = useHistory();
  const [openReportDialog, setOpenReportDialog] = useState(false);

  function handleBackClick() {
    if (routerState.from === "ownerAds") {
      dispatchRouter({
        type: "CHANGE_ROUTER_STATE",
        payload: { from: "mainApp" },
      });
    }
    history.goBack();
  }

  console.log("routerState", routerState);

  return (
    <AppBar
      position="fixed"
      // color="default"
      elevation={0}
      sx={{
        backgroundColor: "white",
        // need to use rgba to make the AppBar transparent but blur the content behind it (color - white)
        // backgroundColor: "rgba(255, 255, 255, 0.7)",
        // backgroundColor: "rgba(245, 245, 245, 0.7)",
        backdropFilter: "blur(10px)", // blur the content behind the AppBar
        mt: 7,
        borderBottom: "1px solid lightgrey",
      }}
    >
      <Toolbar variant="dense" disableGutters sx={{ pb: 0.5 }}>
        {routerState.from === "dashboard" || routerState.from === "ownerAds" ? (
          <>
            {isSmallOrMobile && (
              <IconButton
                onClick={handleBackClick}
                size="small"
                sx={{ mx: 0.5 }}
              >
                <ArrowBackIcon fontSize="small" color="action" />
              </IconButton>
            )}
            {!isSmallOrMobile && (
              <Button
                size="small"
                variant="outlined"
                onClick={handleBackClick}
                sx={{ borderColor: grey[500], mx: 1 }}
                startIcon={<ArrowBackIcon fontSize="small" color="action" />}
              >
                <Typography
                  color="text.primary"
                  variant="caption"
                  sx={{ mt: "2px" }}
                >
                  Atgal
                </Typography>
              </Button>
            )}
          </>
        ) : (
          <NavigatePreviousNext setActiveSlide={setActiveSlide} />
        )}

        {ad && ad.properties.status !== "expired" && (
          <Box
            sx={{
              display: "flex",
              border: "none",
              justifyContent: "right",
              alignItems: "center",
              flexGrow: 1,
              pr: 2,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{ border: "none", display: "flex", mx: 0 }}
            >
              <IconButton onClick={() => handleLikeClick(ad.id)} size="small">
                <FavoriteIcon
                  fontSize="small"
                  sx={{
                    color: userLikes ? red[500] : "default",
                    opacity: userLikes ? 1 : 0.5,
                  }}
                />
              </IconButton>
              <ShareAd ad={ad} />
              <IconButton
                size="small"
                onClick={() => setOpenReportDialog(true)}
              >
                <WarningAmberIcon
                  fontSize="small"
                  color={
                    userState?.adComplaints?.includes(ad?.id)
                      ? "warning"
                      : "action"
                  }
                />
              </IconButton>
            </Stack>
          </Box>
        )}
      </Toolbar>
      {openReportDialog && (
        <ReportAdDialog open={openReportDialog} setOpen={setOpenReportDialog} />
      )}
    </AppBar>
  );
}
