import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function useBackHandler(handleCloseDialog) {
  const history = useHistory();

  useEffect(() => {
    // alert("useBackHandler");
    history.push(window.location.pathname + window.location.search);

    // Function to handle the Escape button event
    const handleBackButtonEvent = (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        history.goBack();
        handleCloseDialog();
      }
    };

    // Function to handle popstate event
    const handlePopStateEvent = (e) => {
      handleCloseDialog();
    };

    window.addEventListener("keydown", handleBackButtonEvent);
    window.addEventListener("popstate", handlePopStateEvent);
    return () => {
      window.removeEventListener("keydown", handleBackButtonEvent);
      window.removeEventListener("popstate", handlePopStateEvent);
    };
  }, []);

  return null;
}
