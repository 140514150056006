import { Box, Dialog, Menu } from "@mui/material";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import useBackHandler from "../../../../hooks/useBackHandler";

export default function DialogOrMenu({ children, ...props }) {
  const { isSmallOrMobile } = useCustomMediaQuery();
  const setAnchorEl = props?.setAnchorEl;
  const rect = isSmallOrMobile
    ? null
    : props?.anchorEl?.getBoundingClientRect();
  let viewportHeight = window.innerHeight;
  useBackHandler(handleBackButton);

  function handleBackButton() {
    if (setAnchorEl) {
      setAnchorEl(null);
    }
  }

  // console.log("anchorEl", props.anchorEl.getBoundingClientRect());
  console.log("DialogOrMenu props", props);

  return isSmallOrMobile ? (
    <Dialog fullScreen open={!!props.anchorEl}>
      {children}
    </Dialog>
  ) : (
    <Menu
      anchorEl={props.anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      getContentAnchorEl={null}
      variant="menu"
      open={!!props.anchorEl}
      onClose={() => {
        props?.formikRef?.current?.submitForm();
      }}
      slotProps={{
        paper: {
          sx: {
            width: rect.width,
            height: viewportHeight - rect.bottom,
            visibility: props.visibility,
          },
        },
      }}
      marginThreshold={0}
      MenuListProps={{ sx: { py: 0 } }}
    >
      <Box
        sx={{
          height: viewportHeight - rect.bottom,
          border: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Menu>
  );
}
